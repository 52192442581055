$(() => {
	const body = $('body')
	const themeSwitch = $('.theme-switch')
	const menuToggle = $('#menu-toggle-button')
	const nav = $('header nav')

	const setDarkTheme = (dark) => {
		if (dark) {
			body.parent().addClass('dark')
		} else {
			body.parent().removeClass('dark')
		}
	}

	const toggleTheme = () => {
		const newDark = !body.parent().hasClass('dark')
		setDarkTheme(newDark)
		localStorage.setItem('current-theme', newDark ? 'dark' : 'light')
	}

	const toggleMenu = () => {
		const newExpanded = menuToggle.attr('aria-expanded') != 'true'
		if (newExpanded) {
			nav.addClass('menu-open')
		} else {
			nav.removeClass('menu-open')
		}
		menuToggle.attr('aria-expanded', newExpanded)
		$(document).trigger('menu:expanded', newExpanded)
	}

	const handleScroll = () => {
		if (window.pageYOffset > 0) {
			body.addClass('scrolled')
		} else {
			body.removeClass('scrolled')
		}
	}

	themeSwitch.on('click', toggleTheme)
	menuToggle.on('click', toggleMenu)
	window.addEventListener('scroll', handleScroll)
})
